import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FaviconService {
  changeFavicon(iconUrl: string): void {
    const link: HTMLLinkElement | null = document.querySelector('#favicon');
    if (link) {
      link.href = iconUrl;
    } else {
      // If the favicon link element doesn't exist, create one
      const newLink = document.createElement('link');
      newLink.id = 'favicon';
      newLink.rel = 'icon';
      newLink.type = 'image/x-icon';
      newLink.href = iconUrl;
      document.head.append(newLink);
    }
  }
}
