import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';
import { BrandingService } from '@app/core/services/branding.service';

@Injectable()
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private brandingService: BrandingService
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const url = await this.authService.getAuthUrl();

    const token = await this.authService.getRefreshedAccessToken();
    // following code has to be executed synchroneously otherwise it gets
    // into logout loop when token is empty
    if (token === '') {
      window.location.href = url;
      return false;
    }
    return true;
  }
}
